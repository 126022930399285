import Reports from '@screens/reports'
import { graphql } from 'gatsby'
import React from 'react'

const ReportsPage = ({ data }) => <Reports data={data} />

export default ReportsPage

export const query = graphql`
  query ReportQuery {
    allContentfulReport {
      edges {
        node {
          id
          title
          content {
            raw
          }
          slug
          coverImage {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            file {
              url
            }
            title
          }
          createdAt
          updatedAt
          date
        }
      }
    }
  }
`
